.list {
  justify-content: center;
}

.accordion {
  border: 2px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 50%;
  border-radius: 10px;
  text-align: left;
  transition: 0.4s;
  font-family: "Roboto Mono", monospace;
  margin: 1rem 5rem;
}

.active,
.accordion:hover {
  background-color: #fff;
  width: 52%;
}

.panel {
  margin-top: 1rem;
  background-color: white;
  overflow: hidden;
  display: none;
  transition: max-height 0.2s ease-out;
}

.panel.show {
  display: block;
}

li {
  padding: 10px;
}

.button-header {
  display: flex;
  justify-content: space-between;
}

.button-header span {
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .list {
    justify-content: center;
  }

  .accordion {
    border: 2px solid black;
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border-radius: 10px;
    text-align: left;
    transition: 0.4s;
    font-family: "Roboto Mono", monospace;
    margin: 1rem 0rem;
  }

  .button-header p {
    font-size: x-small;
  }

  .button-header span {
    font-size: 10px;
  }
  

  .active,
  .accordion:hover {
    background-color: #fff;
    width: 100%;
  }

  .panel {
    margin-top: 1rem;
    background-color: white;
    overflow: hidden;
    display: none;
    transition: max-height 0.2s ease-out;
  }

  .panel.show {
    display: block;
  }

  li {
    padding: 10px;
  }
}
