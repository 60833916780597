.card {
  position: sticky;
  top: 100px;
  background: white;
  padding: 1.5rem;
  box-shadow: 4px 4px #323232;
  border-radius: 4px;
  height: 500px;
  border: 2px solid #323232;
  display: flex;
  justify-content: center;
}

.card-button {
  border: 2px solid black;
  background-color: white;
  color: black;
  cursor: pointer;
  padding: 18px;
  width: 50%;
  border-radius: 5px;
  text-align: center;
  font-family: "Roboto Mono", monospace;
}

.image-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  align-items: center;
}

.card-button:hover {
  background-color: #323232;
  color: white;
  transition: 0.4s;
}

.description {
  margin-right: 1rem;
}

.card p {
  font-size: 14px;
}

.card-image {
  height: 70%;
  border: 2px solid black;
}

.card:nth-child(1) {
  top: 100px;
  rotate: 0.25deg;
}

.card:nth-child(2) {
  top: 120px;
  rotate: 0.5deg;
}

.card:nth-child(3) {
  top: 140px;
  rotate: 0.75deg;
}

.card:nth-child(4) {
  top: 160px;
  rotate: 1deg;
}

.card:nth-child(5) {
  top: 180px;
  rotate: 1.25deg;
}

.card:nth-child(6) {
  top: 200px;
  rotate: 1.5deg;
}

@media only screen and (max-width: 600px) {
  .card {
    position: sticky;
    top: 100px;
    background: white;
    padding: 1.5rem;
    box-shadow: 4px 4px #323232;
    border-radius: 4px;
    height: max-content;
    border: 2px solid #323232;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 10rem;
  }

  .card p {
    font-size: small;
  }

  .card h2 {
    font-size: medium;
  }

  .card-button {
    border: 2px solid black;
    background-color: white;
    color: black;
    cursor: pointer;
    padding: 18px;
    width: 50%;
    border-radius: 5px;
    text-align: center;
    font-family: "Roboto Mono", monospace;
  }

  .image-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
  }

  .card-button:hover {
    background-color: #323232;
    color: white;
    transition: 0.4s;
  }

  .card-image {
    height: 50%;
    width: 250px;
    border: 2px solid black;
  }

  .description {
    margin-right: 0;
  }
  
}
